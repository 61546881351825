import { Component } from '@angular/core';

@Component({
  selector: 'giq-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent {
  public openGuide() {
    window.open('/assets/quick-start-guide.pdf', '_blank');
  }

  public openDNADashboard() {
    window.open('https://devglobelifeinc.atlassian.net/jira/plans/2/scenarios/2/timeline?vid=13', '_blank');
  } 
}
