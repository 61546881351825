<giq-bread-crumbs [crumbs]="crumbs"></giq-bread-crumbs>
<ng-container *ngIf="elements as highs">
  <ng-container *ngIf="containsElements">
    <div class="title">
      <h3>Customize Highlights <span class="beta"></span></h3>
      <span *ngIf="!saving" class="button-link" (click)="saveChanges()">
        Save
      </span>
      <giq-small-load *ngIf="saving"></giq-small-load>
    </div>
    <div class="max-cards-info">(Max of 15 cards can be selected)</div>
    <div class="highlights-content" cdkDropListGroup>
      <div
        cdkDropList
        class="drop-element"
        *ngFor="let high of highs; let i = index"
        [cdkDropListData]="i"
      >
        <div
          class="drag-item"
          cdkDrag
          [cdkDragData]="i"
          (cdkDragEntered)="dragEntered($event)"
        >
          <mat-slide-toggle
            class="slide"
            color="accent"
            [checked]="high.active"
            (change)="slideChange($event, high)"
            [disabled]="isToggleDisabled(high)"
          ></mat-slide-toggle>
          <div class="highlight" [class.inactive]="!high.active">
            <giq-highlight class="chart" [config]="high"></giq-highlight>
          </div>
          <div class="description">
            <span> {{ high.name }} </span> {{ high.description }}
            <ng-container *ngIf="high.analyst">
              <br />
              <br />
              <span> Business Owner: </span> {{ high.analyst }}
            </ng-container>
            <ng-container
              *ngIf="
                high.refreshFrequency && high.refreshFrequency != 'UNKNOWN'
              "
            >
              <br />
              <br />
              <span> Refresh Frequency: </span>
              {{ high.refreshFrequency }}
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
