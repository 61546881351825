<div class="highlight" *ngIf="config">
  <div class="chart" [ngClass]="{'multi-column': metadata.elements.length > 2, 'single-column': metadata.elements.length == 1, 'with-border': metadata.elements.length >= 2}">
    <div class="element" *ngFor="let item of metadata.elements; let i =index">
      <div class="split-item-group">
        <span class="quantity">{{ formatData(item.quantity, "number") }}</span>
        <span class="concept" *ngIf="metadata.elements.length <= 2" [matTooltip]="item.concept">{{ item.concept | truncate:(35 -((20*i)/i)):metadata.elements.length >= 2 }}</span>
      </div>     
      <span class="information" [ngClass]="item?.positive ?'positive-information': 'negative-information'">{{ item.information }}</span>
      <span class="concept" *ngIf="metadata.elements.length > 2" [matTooltip]="item.concept">{{ item.concept | truncate:(35 -((20*i)/i)):metadata.elements.length >= 2 }}</span>
    </div>
  </div>
</div>