export const AGENT_AUTOMATION = 'agent_insight_automation_form';
export const UNDERWRITING = 'underwriting';
export const AGENT_AUTOMATION_SAVE = 'agent_insight_automation_save';
export const AGENT_AUTOMATION_MODIFY = 'agent_insight_automation_modify';
export const CUSTOMER_CARE = 'ps_entry_form';
export const WORKSITE_TRACKER = 'worksitetracker_form';
export const EXTRACTS = 'extracts';
export const RECORD_CATALOG = 'RECORD_CATALOG';
export const PSFORM_ADMIN = 'ps_entry_form_admin';
export const WORKSITE_TRACKER_VOID = 'worksitetracker_void';
export const BANK_COMPLIANCE = 'bank_compliance';
export const CLAIM_AUDIT_LOGS = 'claim_audit_logs';
export const PRODUCTION_TASK = 'production_tasks';
export const PRODUCTION_TASK_CREATE = 'production_tasks_create';
export const PRODUCTION_TASK_MODIFY = 'production_tasks_modify';
export const PRODUCTION_TASK_SUPERVISOR = 'production_tasks_supervisor';
export const SALESBOARD = 'salesboard';

// Chatbots
export const CHATBOT_CUSTOMESERVICE = 'chatbot_customerservice';
export const CHATBOT_DOCUMENT = 'chatbot_document';
export const CHATS = 'chatbot';
export const ADMIN = 'admin';
