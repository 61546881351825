<h3>Support</h3>
<div class="workspace-content">
  <div class="support-menu" [routerLink]="['faq']">
    <span class="name">FAQ</span>
    <span>Frequently Asked Questions</span>
  </div>
  <div class="support-menu" (click)="openGuide()">
    <span class="name">Get Started</span>
    <span>Quick Start Guide</span>
  </div>
  <div class="support-menu" (click)="openDNADashboard()">
    <span class="name">Road map</span>
    <span>eDNA</span>
  </div>
</div>
