import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import * as d3 from 'd3';
import * as moment from 'moment';
import { VerticalBarMetadata, TimeScale, VerticalBarHighlightElement } from './vertical-bar-highlight.metadata';


@Component({
  selector: 'giq-vertical-bar-highlight',
  templateUrl: './vertical-bar-highlight.component.html',
  styleUrls: ['./vertical-bar-highlight.component.scss']
})
export class VerticalBarHighlightComponent implements OnChanges, AfterViewInit {
  @Input() config: Highlight | undefined;
  public id: string = 'vertical-bar-' + Math.ceil(Math.random() * 100000);
  public metadataSets: VerticalBarMetadata[] = [];
  public selectedIndex: number = 0;

  private metadata: VerticalBarMetadata = {
    subtitle: '',
    data: [],
    timeScale: TimeScale.Monthly,
    division: ''
  };

  private finalData: { date: string, amount: number, year: string }[] = [];
  private uniqueYears: string[] = [];

  ngAfterViewInit(): void {
    this.processInfo();
    this.initializeChart();
  }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };
      const elements = this.config?.metadata['data'];
      if (elements) {
        this.metadataSets = Object.values(elements).map((ob: any) => ({
          data: Object.values(ob).filter((item: any) => typeof item === 'object') as VerticalBarHighlightElement[],
          subtitle: ob.subtitle || '',
          division: ob.division || '',
          timeScale: ob.timeScale || ''
        }));
      }
      this.metadata = this.metadataSets[this.selectedIndex];
    }
  }

  getSubtitles() {
    const elements = this.config?.metadata['data'];
    if (Array.isArray(elements) == false) {
      return [];
    }
    else {
      return Object.values(elements as VerticalBarMetadata[])?.map((item: VerticalBarMetadata) => {
        return item.division
      });
    }
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    this.clearGraph();
    this.processInfo();
    this.initializeChart();
  }


  private processInfo(): void {
    const uniqueYearsSet = new Set<string>();

    this.finalData = this.metadata.data
      .map(item => {
        const parsedDate = moment(item.date, 'YYYY-MM-DD');
        const year = parsedDate.year().toString();

        return {
          date: item.date,
          amount: item.amount,
          year: year
        };
      })
      .sort((a, b) => moment(a.date, 'YYYY-MM-DD').toDate().getTime() - moment(b.date, 'YYYY-MM-DD').toDate().getTime());

    this.finalData.forEach(item => {
      uniqueYearsSet.add(item.year);
    });

    this.uniqueYears = Array.from(uniqueYearsSet);
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private initializeChart(): void {
    setTimeout(() => {
      this.drawChart();
    }, 0);
  }

  private drawChart(): void {
    const margin = { top: 20, right: 30, bottom: 130, left: 230 },
      width = 3600 - margin.left - margin.right,
      height = 1500 - margin.top - margin.bottom;

    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom
        }`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', `translate(${margin.left}, ${margin.top})`);


    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', '#fff')
      .style('padding', '5px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '4px')
      .style('pointer-events', 'none')
      .style('opacity', 0)
      .style('font-size', '10px');


    const x = d3
      .scaleBand()
      .domain(this.finalData.map(d => d.date))
      .range([0, width])
      .padding(0.1);


    const labeledYears = new Set<string>();
    const labeledQuartersByYear = new Map<string, Set<string>>();
    const labeledMonthsByYear = new Map<string, Set<string>>();


    svg
    .append('g')
    .attr('transform', `translate(0,${height})`)
    .call(
      d3.axisBottom(x)
        .tickFormat(d => {
          const dateMoment = moment(d, 'YYYY-MM-DD');
          const year = dateMoment.year().toString();
          const month = dateMoment.format('MMM');
  
          switch (this.metadata.timeScale) {
            case TimeScale.Yearly:
              if (!labeledYears.has(year)) {
                labeledYears.add(year);
                return year;
              } else {
                return '';
              }
  
            case TimeScale.Quarterly:
              const quarterLabel = `Q${Math.floor(dateMoment.month() / 3) + 1}`;
              if (!labeledQuartersByYear.has(year)) {
                labeledQuartersByYear.set(year, new Set<string>());
              }
  
              const labeledQuarters = labeledQuartersByYear.get(year)!;
  
              if (!labeledQuarters.has(quarterLabel)) {
                labeledQuarters.add(quarterLabel);
                return quarterLabel;
              } else {
                return '';
              }
  
            case TimeScale.Monthly:
            default:
              if (!labeledMonthsByYear.has(year)) {
                labeledMonthsByYear.set(year, new Set<string>());
              }
  
              const labeledMonths = labeledMonthsByYear.get(year)!;
  
              if (!labeledMonths.has(month)) {
                labeledMonths.add(month);
                return month;
              } else {
                return '';
              }
          }
        })
        .tickPadding(30)
    )
    .selectAll('text')
    .style('font-size', '10px')
    .style('text-anchor', 'middle');
  

    svg.selectAll('.domain').attr('stroke', 'none');
    svg.selectAll('.tick line').attr('stroke', 'none');
    svg.selectAll('.tick text').style('font-size', '100');


    const y = d3
      .scaleLinear()
      .domain([0, d3.max(this.finalData, d => d.amount)!])
      .range([height, 0]);

    svg.append('g')
      .call(d3.axisLeft(y)
        .ticks(5)
        .tickFormat(d => d === 0 ? '' : d3.format('.2s')(d))
      )
      .style('font-size', '10px')
      .select('.domain').attr('stroke', 'none');
    svg.selectAll('.tick line').attr('stroke', 'none');
    svg.selectAll('.tick text').style('font-size', '100');


    const availableColors = [
      'rgb(240,171,32)',
      'rgb(235,121,58)',
      'rgb(85,170,167)',
      'rgb(37,91,140)',
      'rgb(185,214,90)'
    ];

    const color = d3.scaleOrdinal<string>()
      .domain(this.uniqueYears)
      .range(availableColors);

    svg
      .selectAll('rect')
      .data(this.finalData)
      .enter()
      .append('rect')
      .attr('x', d => x(d.date)!)
      .attr('y', height)
      .attr('width', x.bandwidth())
      .attr('height', 0)
      .attr('fill', d => color(d.year))
      .on('mouseover', function (event, d) {
        tooltip.style('opacity', 1);
      })
      .on('mousemove', function (event, d) {
        tooltip
          .html(`${d.date}<br>${d.amount}`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 28) + 'px');
      })
      .on('mouseout', function () {
        tooltip.style('opacity', 0);
      })
      .transition()
      .duration(1000)
      .attr('y', d => y(d.amount))
      .attr('height', d => height - y(d.amount));

  }

}