import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, map, tap } from 'rxjs';
import { ReportsService } from 'src/app/core/reports.service';
import { Highlight } from 'src/app/models/reports';
import { ReportType } from 'src/app/models/reports/report-type';

@Component({
  selector: 'giq-highlights',
  templateUrl: 'highlights.component.html',
  styleUrls: ['highlights.component.scss'],
})
export class HighlightsComponent {
  public containsElements = false;
  public highlights$: Observable<Highlight[]> = this.reportsService
    .GetHighlights()
    .pipe(
      tap((elements: Highlight[]) => {
        this.containsElements = elements.length > 0;
      }),
      map((elements: Highlight[]) => {
        return elements.sort((a, b) => (a.order ?? 100) - (b.order ?? 100));
      })
    );

  constructor(private reportsService: ReportsService, private router: Router) {}

  public openReport(high: Highlight): void {
    if (high.reportCategoryId == ReportType.App) {
      let navigation = this.reportsService.GetUrlMappingForReportApps(high.id);
      this.router.navigate([navigation]);
    } else {
      this.router.navigate(['reports', high.reportId]);
    }
  }

  public ngAfterViewChecked(): void {
    window.dispatchEvent(new Event('resize'));
  }
}
