import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Highlight } from 'src/app/models/reports';
import { DualHighlightMetadata } from './dual-highlight.metadata';

import * as d3 from 'd3';

@Component({
  selector: 'giq-dual-highlight',
  templateUrl: 'dual-highlight.component.html',
  styleUrls: ['dual-highlight.component.scss'],
})
export class DualHighlightComponent implements AfterViewInit, OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'dual-' + Math.ceil(Math.random() * 100000);

  public metadata: DualHighlightMetadata = {
    colorLeft: '244,117,46',
    colorRight: '62,140,191',
    labelLeft: '',
    labelRight: '',
    data: [],
  };
  ngAfterViewInit(): void {
    this.createSvg();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null) {
      this.metadata = { ...this.metadata, ...this.config?.metadata };
    }
  }

  private createSvg(): void {
    const margin = {
        top: 0,
        right: 0,
        bottom: 15,
        left: 50,
        gap: 10,
        graph: 50,
      },
      width = 500 - margin.left - margin.right - margin.gap,
      height = 250 - margin.top - margin.bottom;
    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${
          width + margin.left + margin.right + margin.gap + margin.graph
        } ${height + margin.top + margin.bottom}`
      )
      .attr('preserveAspectRatio', 'none')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const x = d3
      .scaleLinear()
      .domain([0, 100])
      .range([0, width / 2]);

    const y = d3
      .scaleBand()
      .range([1, height])
      .domain(
        this.metadata.data.map((d) => {
          return d.key;
        })
      )
      .padding(0.1);
    const axis = svg.append('g').call(d3.axisLeft(y).tickSize(0));

    axis.select('.domain').attr('stroke', 'transparent');

    svg
      .selectAll('rightBar')
      .data(this.metadata.data)
      .enter()
      .append('rect')
      .attr('x', x(100) + margin.gap + margin.graph)
      .attr('y', (d) => {
        return (y(d.key) as number) + 3;
      })
      .attr('width', function (d) {
        return x(0);
      })
      .attr('rx', 15)
      .attr('height', y.bandwidth() - 10)
      .attr('fill', `rgb(${this.metadata.colorRight})`)
      .transition()
      .duration(800)
      .attr('width', function (d) {
        return x(d.right);
      });

    svg
      .selectAll('leftBar')
      .data(this.metadata.data)
      .enter()
      .append('rect')
      .attr('x', (d) => x(100) + margin.graph)
      .attr('y', (d) => {
        return (y(d.key) as number) + 3;
      })
      .attr('stroke-linecap', 'round')
      .attr('width', function (d) {
        return x(0);
      })
      .attr('rx', 15)
      .attr('height', y.bandwidth() - 10)
      .attr('fill', `rgb(${this.metadata.colorLeft})`)
      .transition()
      .duration(800)
      .attr('width', function (d) {
        return x(d.left);
      })
      .attr('x', (d) => x(100) - x(d.left) + margin.graph);

    svg
      .append('text')
      .text(this.metadata.labelRight)
      .attr('text-anchor', 'start')
      .attr('dominant-baseline', 'hanging')
      .attr('dy', height + margin.top)
      .attr('dx', width / 2 + margin.gap + margin.graph)
      .style('font-size', 15)
      .style('font-weight', 'bold');

    svg
      .append('text')
      .text(this.metadata.labelLeft)
      .attr('text-anchor', 'end')
      .attr('dominant-baseline', 'hanging')
      .attr('dy', height + margin.top)
      .attr('dx', width / 2 + margin.graph)
      .style('font-size', 15)
      .style('font-weight', 'bold');
  }
}
