import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Highlight, HighlightType } from '../../../models/reports';

@Component({
  selector: 'giq-highlight',
  templateUrl: 'highlight.component.html',
  styleUrls: ['highlight.component.scss'],
})
export class HighlightComponent implements OnInit {
  @Input() config: Highlight | null = null;
  @ViewChild('image', { static: true }) image?: TemplateRef<any>;
  @ViewChild('splitText', { static: true }) splitText?: TemplateRef<any>;
  @ViewChild('map', { static: true }) map?: TemplateRef<any>;
  @ViewChild('salesScroll', { static: true }) salesScroll?: TemplateRef<any>;
  @ViewChild('tubes', { static: true }) tubes?: TemplateRef<any>;
  @ViewChild('area', { static: true }) area?: TemplateRef<any>;
  @ViewChild('donut', { static: true }) donut?: TemplateRef<any>;
  @ViewChild('line', { static: true }) line?: TemplateRef<any>;
  @ViewChild('dual', { static: true }) dual?: TemplateRef<any>;
  @ViewChild('complexDonut', { static: true }) complexDonut?: TemplateRef<any>;
  @ViewChild('bars', { static: true }) bars?: TemplateRef<any>;
  @ViewChild('scatterplot', { static: true }) scatterplot?: TemplateRef<any>;  
  @ViewChild('horizontalBar', { static: true }) horizontalBar?: TemplateRef<any>;
  @ViewChild('sector', { static: true }) sector?: TemplateRef<any>;
  public templates: { [key: string]: TemplateRef<any> | null } = {};
  selectedIndex = 0;  

  constructor() {}

  ngOnInit() {
    this.templates[HighlightType.Image] = this.image ?? null;
    this.templates[HighlightType.SplitText] = this.splitText ?? null;
    this.templates[HighlightType.Map] = this.map ?? null;
    this.templates[HighlightType.SalesScroll] = this.salesScroll ?? null;
    this.templates[HighlightType.Tubes] = this.tubes ?? null;
    this.templates[HighlightType.Area] = this.area ?? null;
    this.templates[HighlightType.Donut] = this.donut ?? null;
    this.templates[HighlightType.Line] = this.line ?? null;
    this.templates[HighlightType.Dual] = this.dual ?? null;
    this.templates[HighlightType.ComplexDonut] = this.complexDonut ?? null;
    this.templates[HighlightType.Bars] = this.bars ?? null;
    this.templates[HighlightType.Scatterplot] = this.scatterplot ?? null;
    this.templates[HighlightType.HorizontalBar] = this.horizontalBar ?? null;
    this.templates[HighlightType.Sector] = this.sector ?? null;
  }

  handleToggle(index: number, $event: MouseEvent) {
    if (this.config?.onToggleChange != null) {
    $event.stopPropagation();
    $event.preventDefault();
    this.selectedIndex = index;    
    this.config.onToggleChange(index);
  }
  }  

  getActionData(config: Highlight): string[] | false {
    if (config?.getSubtitles != null) {
      const subtitles = config.getSubtitles();
      if (subtitles.length > 1) {
        return subtitles;
      }
    }
    return false;
  } 
}
