<ng-container *ngIf="highlights$ | async as highs">
  <ng-container *ngIf="containsElements">
    <div class="title">
      <h3>Highlights</h3>
      <mat-icon matTooltip="Edit" [routerLink]="['/highlights']"
        >settings</mat-icon
      >
    </div>
    <div class="highlights-content">
      <ng-container *ngFor="let high of highs">
        <div class="highlight-container">
          <div class="highlight" (click)="openReport(high)">
            <giq-highlight [config]="high"></giq-highlight>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</ng-container>
