import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ReportType } from 'src/app/models/reports/report-type';
import { environment } from 'src/environments/environment';
import { Highlight, Report } from '../models/reports';
import { Category } from '../models/reports/category';
import { withErrorMapping } from './errors/with-error-mapping';
import {
  allCategories$,
  allHighlights$,
  changeFavorite,
  getReport$,
  highlightsRetrieved,
  highlightsRetrieved$,
  reportsRetrieved,
  reportsRetrieved$,
  setCategories,
  setHighlights,
  setReports,
} from './state/reports/reports.store';

@Injectable({ providedIn: 'root' })
export class ReportsService {
  constructor(private httpClient: HttpClient) {}

  public getReport(reportId: string): Observable<Report> {
    return getReport$(reportId).pipe(
      switchMap((reportInfo: Report | undefined) => {
        if (reportInfo == null) {
          return this.httpClient
            .get<Report>(environment.apiUrl + '/reports/' + reportId)
            .pipe(
              withErrorMapping,
              tap((info: Report) => {
                setReports([info]);
              })
            );
        } else {
          return of(reportInfo);
        }
      })
    );
  }

  public GetHighlights(): Observable<Highlight[]> {
    return highlightsRetrieved$.pipe(
      switchMap((received: boolean) => {
        if (received == false) {
          return this.httpClient
            .get<Highlight[]>(environment.apiUrl + '/reports/highlights')
            .pipe(withErrorMapping)
            .pipe(
              tap((allHighs: Highlight[]) => {
                setHighlights(allHighs);
                highlightsRetrieved(true);
              })
            );
        } else {
          return allHighlights$;
        }
      })
    );
  }

  public GetWorkspaceCategories(): Observable<Category[]> {
    return reportsRetrieved$.pipe(
      switchMap((retrieved: boolean) => {
        if (retrieved == false) {
          return this.httpClient
            .get<any[]>(environment.apiUrl + '/reports')
            .pipe(withErrorMapping)
            .pipe(
              tap((allReports) => {
                let categories: Category[] = [];
                let reports: Report[] = [];
                for (const cat of allReports) {
                  if (cat.id != 'apps') {
                    categories.push({
                      id: cat.id,
                      name: cat.name,
                      color: cat.color,
                    });
                  }
                  cat.reports.forEach((x: Report) => reports.push(x));
                }
                setReports(reports);
                setCategories(categories);
                reportsRetrieved(true);
              }),
              switchMap((_) => allCategories$)
            );
        } else {
          return allCategories$;
        }
      })
    );
  }

  public SetFavorite(reportId: string, setFavorite: boolean): Observable<void> {
    if (setFavorite === true) {
      return this.httpClient
        .put<void>(
          environment.apiUrl + '/reports/' + reportId + '/favorite',
          {}
        )
        .pipe(
          withErrorMapping,
          tap((_) => {
            changeFavorite(reportId, setFavorite);
          })
        );
    } else {
      return this.httpClient
        .delete<void>(environment.apiUrl + '/reports/' + reportId + '/favorite')
        .pipe(
          withErrorMapping,
          tap((_) => {
            changeFavorite(reportId, setFavorite);
          })
        );
    }
  }

  public GetUrlMappingForReportApps(reportId: string): string {
    switch (reportId) {
      case 'CustomerCareForm':
        return 'exchange/ua-ps-admin';
      case 'agent-automation':
        return 'exchange/agent-automation';
      case 'worksite-tracker':
        return 'exchange/worksite-tracker';
      case 'salesboard':
        return 'exchange/salesboard';
      case 'claim-audit-logs':
        return 'exchange/claim-audit-logs';

      default:
        return 'notfound';
    }
  }

  public openReport(report: Report): string[] {
    if (report.dataSourceId == ReportType.App) {
      let navigation = this.GetUrlMappingForReportApps(report.id);
      return [navigation];
    } else {
      return ['reports', report.id];
    }
  }

  public accessReport(reportId: string): Observable<any> {
    return this.httpClient
      .post<any>(environment.apiUrl + `/reports/${reportId}/access`, {})
      .pipe(withErrorMapping);
  }
}
