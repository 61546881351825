import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CHATS, ADMIN, EXTRACTS } from 'src/app/constants/permissions';
import { hasPermission, userInfo$ } from 'src/app/core/state/user/user.store';
import { environment } from 'src/environments/environment';
import { MenuItem } from './menu-item';

@Component({
  selector: 'giq-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit, OnDestroy {
  public menuOpen: boolean | undefined;
  public menuList: MenuItem[] = [];
  public baseList: MenuItem[] = [
    {
      icon: 'house',
      name: 'Home',
      route: 'home',
      frontAction: null,
      availableOutside: true,
    },
    // {
    //   icon: 'list',
    //   name: 'Data Catalog',
    //   route: 'catalog',
    // },
    {
      icon: 'exchange',
      name: 'Data Exchange',
      route: 'exchange',
      frontAction: null,
      availableOutside: true,
    },
    {
      icon: 'folder',
      name: 'Extracts',
      route: 'extracts',
      frontAction: EXTRACTS,
      availableOutside: false,
    },
    {
      cssIcon: 'forum',
      name: 'Chats',
      route: 'chats',
      frontAction: CHATS,
      availableOutside: false,
    },
    {
      icon: 'help',
      name: 'Support',
      route: 'support',
      frontAction: null,
      availableOutside: true,
    },
    {
      icon: 'admin-panel-settings',
      name: 'Admin',
      route: 'admin',
      frontAction: ADMIN,
      availableOutside: true,
    },
    // {
    //   icon: 'mug',
    //   name: 'Customer Lounge',
    //   route: 'lounge',
    // },
    // {
    //   icon: 'question',
    //   name: 'Help',
    //   route: 'support',
    // },
    // {
    //   icon: 'dialog',
    //   name: 'Chat',
    //   route: 'chat',
    // },
  ];
  constructor() {}

  private subscriptions = new Subscription();

  ngOnInit(): void {
    this.subscriptions.add(
      userInfo$.subscribe((x) => {
        this.menuList = this.baseList.filter(
          (x) =>
            ((environment.fuera && x.availableOutside) || !environment.fuera) &&
            (x.frontAction == null || hasPermission(x.frontAction))
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public changeMenuState(): void {
    this.menuOpen = !this.menuOpen;
  }

  public close() {
    this.menuOpen = false;
  }
}
