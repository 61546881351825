import { 
  Component,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as d3 from 'd3';
import { Highlight } from 'src/app/models/reports';
import {
  LineHighlightElement,
  LineHighlightMetadata,
} from './line-highlight.metadata';

@Component({
  selector: 'giq-line-highlight',
  templateUrl: 'line-highlight.component.html',
  styleUrls: ['line-highlight.component.scss'],
})
export class LineHighlightComponent implements OnChanges {
  @Input() config: Highlight | undefined;

  public id: string = 'line-' + Math.ceil(Math.random() * 100000);
  selectedIndex: number = 0;
  metadataSets!: LineHighlightMetadata[];
  public metadata: LineHighlightMetadata = {
    colors: [],
    data: [],
    isWeekly: false
  };
  isToggleEnabled = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'] != null && this.config != null) {
      this.config.onToggleChange = (indx) => this.updateChartData(indx);
      this.config.getSubtitles = () => {
        return this.getSubtitles();
      };

      const elements = this.config?.metadata as unknown as LineHighlightMetadata;
      if (elements) {
        this.isToggleEnabled = elements?.data?.some((item: LineHighlightElement) => { return item?.division })
        if (this.isToggleEnabled) {
          this.metadataSets = Object.values(elements.data).map((set: any) => {
            const newData = Object.values(set).filter((item: any) => typeof item === 'object') as LineHighlightElement[];
            newData.forEach((x: any) => (x.date = new Date(x.date)))
            return {
              isWeekly: elements.isWeekly,
              colors: elements.colors,
              data: newData
            }
          });
          this.metadata = this.metadataSets[this.selectedIndex];
        }
        else {
          const newMetadata = { ...this.metadata, ...this.config?.metadata };
          newMetadata.data.forEach((x: any) => (x.date = new Date(x.date)));
          this.metadata = newMetadata;
        }
      }
      setTimeout(() => this.createSvg(), 0);
    }
  }

  updateChartData(index: number): void {
    this.selectedIndex = index;
    this.metadata = this.metadataSets[index];
    this.clearGraph();
    this.createSvg();
  }

  getSubtitles(): string[] {
    const elements = this.config?.metadata as unknown as LineHighlightMetadata;
    if (elements?.data?.length <= 1 || !this.isToggleEnabled) {
      return [];
    }
    else {
      return elements?.data?.map((item: LineHighlightElement) => {
        return item.division;
      });
    }
  }

  private clearGraph(): void {
    d3.selectAll(`#${this.id} > *`).remove();
  }

  private createSvg(): void {
    const margin = { top: 0, right: 0, bottom: 50, left: 39 },
      width = 500 - margin.left - margin.right,
      height = 250 - margin.top - margin.bottom;
  
    const svg = d3
      .select(`#${this.id}`)
      .append('svg')
      .attr('width', '100%')
      .attr('height', '100%')
      .attr(
        'viewBox',
        `0 0 ${width + margin.left + margin.right} ${height + margin.top + margin.bottom}`
      )
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  
    const groups = d3.group(this.metadata.data, (d) => d.key);
  
    const domainX: [Date, Date] = d3.extent(
      this.metadata.data,
      (d: LineHighlightElement) => d.date
    ) as [Date, Date];
  
    const x = d3.scaleTime().domain(domainX).range([0, width]);
  
    let xAxis;
    if (this.metadata.isWeekly) {
      const xAxisRange = domainX[1].getMonth() - domainX[0].getMonth();
      const yearRange = domainX[1].getFullYear() - domainX[0].getFullYear();
      const numberOfTickts = (xAxisRange < 5 && yearRange ==0) ? xAxisRange : 5; 
      xAxis = svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(
          d3
            .axisBottom(x)
            .ticks(numberOfTickts)
            .tickSize(0)
            .tickPadding(15)
            .tickFormat(d3.timeFormat('%b') as any)
        );
    }
    else {
      xAxis = svg
        .append('g')
        .attr('transform', 'translate(0,' + height + ')')
        .call(
          d3
            .axisBottom(x)
            .ticks(5)
            .tickSize(0)
            .tickFormat(d3.timeFormat('%Y %q') as any)
        );
    }
  
    xAxis.select('.domain').attr('stroke', '#dcdbdb');
        
    let values: number[] = [];
    let keys: string[] = [];
    groups.forEach((x: LineHighlightElement[]) => {
      let key = '';
      x.forEach((y) => {
        values.push(y.yValue);
        key = y.key;
      });
      keys.push(key);
    });
  
    const domainY: [number, number] = d3.extent(values) as [number, number];
    const maxY = domainY[1] ? domainY[1] * 1.15 : 1;
    const y = d3.scaleLinear().domain([domainY[0], maxY]).range([height, 0]);
  
    const yAxis = svg
      .append('g')
      .call(
        d3.axisLeft(y)
          .ticks(5)
          .tickSize(0)
          .tickPadding(15)
          .tickFormat(d3.format('.2s'))
      );
  
    yAxis.select('.domain').attr('stroke', '#dcdbdb');
  
    const color = d3.scaleOrdinal().domain(keys).range(this.metadata.colors);
  
    const line = d3
      .line()
      .x((d: any) => x(d.date))
      .y((d: any) => y(d.yValue));
  
    svg
      .selectAll('.line')
      .data(groups)
      .enter()
      .append('path')
      .attr('fill', 'none')
      .attr('class', 'line')
      .attr('stroke', (d) => color(d[0]) as any)
      .attr('stroke-width', 4.5)
      .attr('d', (d) => line(Array.from(d.values())[1] as any))
      .attr('stroke-dasharray', function () {
        const totalLength: number = this.getTotalLength();
        return totalLength + ' ' + totalLength;
      })
      .attr('stroke-dashoffset', function (d) {
        return this.getTotalLength();
      })
      .transition()
      .duration(1000)
      .ease(d3.easeLinear)
      .attr('stroke-dashoffset', 0);
  
    const tooltip = d3
      .select('body')
      .append('div')
      .attr('class', 'line-tooltip')
      .style('opacity', 0)
      .style('position', 'absolute')
      .style('z-index', 2)
      .style('border', '1px solid #ddd')
      .style('background-color', 'white')
      .style('padding', '5px');
  
    const formatDate = d3.timeFormat('%b %d %Y');
    const formatValue = (value: number) => {
      if (value < 100000) return value.toString();
      return d3.format('.2s')(value).replace(/\.0$/, '');
    };
    svg
      .selectAll('.dot')
      .data(this.metadata.data)
      .enter()
      .append('circle')
      .attr('class', 'dot')
      .attr('cx', (d) => x(d.date))
      .attr('cy', (d) => y(d.yValue))
      .attr('r', 2.5)
      .attr('fill', (d) => color(d.key) as any)
      .on('mouseover', function (event, d) {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip
          .html(
            `Date: ${formatDate(d.date)}<br>Value: ${formatValue(d.yValue)}`
          )
          .style('left', event.pageX + 5 + 'px')
          .style('top', event.pageY - 28 + 'px');
        d3.select(this).transition().duration(200).attr('r', 6);
      })
      .on('mouseout', function () {
        tooltip.transition().duration(500).style('opacity', 0);
        d3.select(this).transition().duration(500).attr('r', 2.5);
      });
  
    const legendWidthPerItem = 100;
    const totalLegendWidth = keys.length * legendWidthPerItem;
    const legendXOffset = (width - totalLegendWidth)/2;
  
    const legend = svg
      .append('g')
      .attr('transform', `translate(${legendXOffset}, ${height + 40})`)
      .attr('class', 'legend-group');
  
    const legendItem = legend
      .selectAll('.legend-item')
      .data(keys)
      .enter()
      .append('g')
      .attr('class', 'legend-item')
      .attr('transform', (d, i) => `translate(${i * 100}, 0)`);
  
    legendItem
      .append('circle')
      .attr('cx', 0)
      .attr('cy', 0)
      .attr('r', 5)
      .style('fill', (d) => color(d) as string);
  
    legendItem
      .append('text')
      .attr('x', 15)
      .attr('y', 0)
      .style('text-anchor', 'start')
      .style('dominant-baseline', 'middle')
      .text((d) => d);

    }
}
