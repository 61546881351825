import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import {
  BehaviorSubject,
  Observable,
  catchError,
  filter,
  map,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { Crumb } from 'src/app/common/bread-crumbs/crumb';
import { AlertService } from 'src/app/core/alerts/alerts.service';
import { GIQError } from 'src/app/core/errors/giq-error';
import { Report } from 'src/app/models/reports';
import { ReportsService } from '../../core/reports.service';
declare var tableau: any;

@Component({
  selector: 'giq-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnDestroy {
  public viz: any;
  public crumbs: Crumb[] = [];
  public reportInfo$: Observable<Report | undefined>;
  public error$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public errorMessage: string = '';
  public customWidth = false;
  public reportInfo: Report | undefined = undefined;
  public reportId: string;
  public reportDescription: string | undefined;

  constructor(
    activatedRoute: ActivatedRoute,
    private reportsService: ReportsService,
    private alertService: AlertService
  ) {
    this.reportId = activatedRoute.snapshot.params['reportId'];
    this.reportInfo$ = activatedRoute.params.pipe(
      map((params: Params) => {
        return params['reportId'];
      }),
      filter((x) => x != null && x !== '' && x !== 'null'),
      switchMap((reportId: string) => {
        return reportsService.getReport(reportId);
      }),
      tap((reportInfo: Report | undefined) => {
        if (reportInfo == null) {
          this.error$.next(true);
          this.errorMessage = 'Error loading report information.';
          this.reportDescription = 'Error loading report information';
        } else {
          this.reportDescription =
            reportInfo?.categoryName + '/' + reportInfo.name;
          this.crumbs = [
            { name: 'Reports', url: '/home' },
            {
              name: reportInfo?.categoryName ?? '',
              url: '/reports',
              query: { category: reportInfo.categoryId },
            },
            { name: reportInfo?.name ?? '', url: undefined },
          ];
          if (reportInfo.url != null) {
            this.reportInfo = reportInfo;
            setTimeout(() => {
              if (this.reportInfo?.dataSourceId == 'TABLEAU') {
                this.initTableau(reportInfo);
              } else {
                this.initIFrame(reportInfo);
              }
            }, 200);
          } else {
            throwError(() => {
              errorMessage: 'Incorrect report configuration';
            });
          }
        }
      }),
      catchError((err: GIQError) => {
        this.error$.next(true);
        this.errorMessage = err.errorMessage;
        return throwError(() => err);
      })
    );
  }

  ngOnDestroy(): void {
    if (this.viz) {
      this.viz.dispose();
    }
  }

  initIFrame(reportInfo: Report) {
    const containerDiv = document.getElementById('vizContainer');
    const iframe = document.createElement('iframe');
    iframe.src = reportInfo.url ?? '';
    iframe.style.width = '100%';
    iframe.style.height = '100%';
    containerDiv?.appendChild(iframe);
  }

  initTableau(reportInfo: Report) {
    const containerDiv = document.getElementById('vizContainer');
    const options: any = {
      hideTabs: !reportInfo.showTabs ?? true,
      hideToolbar: !reportInfo.showToolbar ?? true,
      device: 'desktop',
      onFirstInteractive: () => {
        this.reportsService.accessReport(this.reportId).subscribe({
          error: (err: GIQError) => {
            this.alertService.error(err.errorMessage);
          },
        });
      },
    };

    if (reportInfo.width != null) {
      options.width = reportInfo.width;
      this.customWidth = true;
    }

    if (reportInfo.height != null) {
      options.height = reportInfo.height;
    }

    this.viz = new tableau.Viz(containerDiv!, reportInfo.url, options);
  }
}
